import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { Select } from '@components';
import { IFilters, LocalStorageKey, MeasurementType } from '@api/types';
import React, { useEffect } from 'react';
import { getItem } from '@services';

import { InputsContainer, SelectWrapper, SwitcherWrapper, useStyles } from './inputs.styles';
import { useGetBuildingsQuery, useGetCities, useGetStreetsQuery } from '@api/locations';
import { useFilter } from '../use-filter.hook';

interface IInputsProps {
  onChangeParams: (params: IFilters) => void;
  initialParams: IFilters;
  isReportBar: boolean;
}

export const Inputs = ({ onChangeParams, initialParams, isReportBar }: IInputsProps) => {
  let filters = isReportBar ? initialParams : getItem<IFilters>(LocalStorageKey.FILTERS);
  const { classes } = useStyles();
  const {
    params: { buildingId, cityId, forDay, measurementTypes, onlyAlerts, streetId },
    filtersChange,
  } = useFilter({ initialParams: filters || initialParams, onChangeParams });
  const { data: cities, isLoading: isLoadingCities } = useGetCities();
  const {
    data: streets,
    refetch: refetchStreets,
    isRefetching: isRefetchingStreets,
  } = useGetStreetsQuery({ cityId: cityId || filters?.cityId || '' });
  const {
    data: buildingsNumber,
    isRefetching: isRefetchingBuildings,
    refetch: refetchBuildings,
  } = useGetBuildingsQuery({
    streetId: streetId || filters?.cityId || '',
  });

  const handleOnCitySelect = (event: SelectChangeEvent<any>) => {
    filtersChange('cityId', event.target.value);
  };

  const handleOnStreetSelect = (event: SelectChangeEvent<any>) => {
    filtersChange('streetId', event.target.value);
  };
  const handleOnBuildingSelect = (event: SelectChangeEvent<any>) => {
    filtersChange('buildingId', event.target.value);
  };

  const handleOnlyAlertsSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    filtersChange('onlyAlerts', event.target.checked.toString());
  };

  const handleDayOfMonthChange = (value: number) => {
    filtersChange('forDay', value);
  };

  const handleMeasurementTypeChange = (value: MeasurementType[]) => {
    filtersChange('measurementTypes', value);
  };

  useEffect(() => {
    if (cityId) {
      refetchStreets();
    }
  }, [refetchStreets, cityId]);

  useEffect(() => {
    if (streetId) {
      refetchBuildings();
    }
  }, [refetchBuildings, streetId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <InputsContainer>
        <SelectWrapper>
          <InputLabel id="city-select-label">Miasto</InputLabel>
          <Select
            value={cityId || ''}
            labelId="city-select-label"
            id="city-select"
            label="Miasto"
            disabled={isLoadingCities}
            onChange={handleOnCitySelect}
          >
            <MenuItem value={''}>Wybierz miasto</MenuItem>
            {cities &&
              cities?.data.map((city) => {
                return (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                );
              })}
          </Select>
        </SelectWrapper>
        <SelectWrapper>
          <InputLabel id="street-select-label">Ulica</InputLabel>
          <Select
            value={cityId ? streetId : ''}
            labelId="street-select-label"
            id="street-select"
            label="Ulica"
            onChange={handleOnStreetSelect}
            disabled={isRefetchingStreets || !cityId}
          >
            <MenuItem value={''}>Wybierz ulice</MenuItem>
            {streets?.data.map((street) => {
              return (
                <MenuItem key={street.id} value={street.id}>
                  {street.name}
                </MenuItem>
              );
            })}
          </Select>
        </SelectWrapper>
        <SelectWrapper>
          <InputLabel id="building-number-select-label">Numer Budynku</InputLabel>
          <Select
            value={streetId ? buildingId : ''}
            labelId="building-number-select-label"
            id="building-number-select"
            label="Numer Budynku"
            disabled={isRefetchingBuildings || !streetId}
            onChange={handleOnBuildingSelect}
          >
            <MenuItem value={''}>Wybierz budynek</MenuItem>
            {buildingsNumber?.data.map((building) => {
              return (
                <MenuItem key={building.id} value={building.id}>
                  {building.name}
                </MenuItem>
              );
            })}
          </Select>
        </SelectWrapper>

        {isReportBar && (
          <SelectWrapper>
            <Tooltip
              placement={'right-start'}
              title="Dzień w miesiącu, na który zostanie wygenerowany raport.
              W przypadku gdy obecny miesiąc jest krótszy bądź dłuższy,
              niż podana wartość – system automatycznie dostosuje odpowiedni dzień, który będzie najbliżej tego wprowadzonego przez użytkownika"
            >
              <TextField
                id="month-day"
                type="number"
                label="Dzień miesiąca"
                value={forDay || ''}
                onChange={(event) => {
                  const value = parseInt(event.target.value);
                  if (value > 31) {
                    handleDayOfMonthChange(31);
                    return;
                  }
                  if (value === 0) {
                    handleDayOfMonthChange(1);
                    return;
                  }
                  handleDayOfMonthChange(value);
                }}
                InputProps={{
                  classes: { input: classes.container },
                  inputProps: { min: 1 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
              />
            </Tooltip>
          </SelectWrapper>
        )}
        {!isReportBar && (
          <SwitcherWrapper>
            <FormControlLabel
              control={<Switch checked={onlyAlerts === 'true'} onChange={handleOnlyAlertsSelect} />}
              label="Tylko alerty"
              labelPlacement="end"
            />
          </SwitcherWrapper>
        )}
      </InputsContainer>
      {isReportBar && (
        <FormGroup aria-label="position" row style={{ display: 'flex', flexDirection: 'row', gap: 16, margin: 6 }}>
          <FormControlLabel
            value={'HotWater'}
            control={
              <Checkbox
                onChange={(event) => {
                  if (event.target.checked) {
                    handleMeasurementTypeChange([...(measurementTypes as MeasurementType[]), 'HotWater']);
                  } else {
                    handleMeasurementTypeChange(
                      [...(measurementTypes as MeasurementType[])].filter((type) => type !== 'HotWater')
                    );
                  }
                }}
              />
            }
            label="Ciepła woda"
            labelPlacement="end"
          />
          <FormControlLabel
            value={'ColdWater'}
            control={
              <Checkbox
                onChange={(event) => {
                  if (event.target.checked) {
                    handleMeasurementTypeChange([...(measurementTypes as MeasurementType[]), 'ColdWater']);
                  } else {
                    handleMeasurementTypeChange(
                      [...(measurementTypes as MeasurementType[])].filter((type) => type !== 'ColdWater')
                    );
                  }
                }}
              />
            }
            label="Zimna woda"
            labelPlacement="end"
          />
          <FormControlLabel
            value={'Heat'}
            control={
              <Checkbox
                onChange={(event) => {
                  if (event.target.checked) {
                    handleMeasurementTypeChange([...(measurementTypes as MeasurementType[]), 'Heat']);
                  } else {
                    handleMeasurementTypeChange(
                      [...(measurementTypes as MeasurementType[])].filter((type) => type !== 'Heat')
                    );
                  }
                }}
              />
            }
            label="Ciepłomierz"
            labelPlacement="end"
          />
        </FormGroup>
      )}
    </div>
  );
};
