import { useContext, useMemo } from 'react';
import { UserActionType, UserContext } from '@contexts';
import { getStoredCurrentCustomerData, getStoredSessionData } from '@helpers';
import { httpsService } from '@services';

import { SnackbarActionType, SnackbarContext } from '../SnackbarContext';
import { SnackbarType } from '../SnackbarContext/types';

export const HttpProvider: React.FC = ({ children }) => {
  const { userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);

  useMemo(() => {
    httpsService.interceptors.request.use(
      (config) => {
        if (!config?.headers) {
          throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
        }

        const storedSessionData = getStoredSessionData();
        const storedCustomer = getStoredCurrentCustomerData();

        config.headers['Authorization'] = `Bearer ${storedSessionData?.accessToken}`;
        config.headers['tenant-id'] = `${storedCustomer?.id}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    httpsService.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.message === 'Network Error') {
          userDispatch({ type: UserActionType.LOG_OUT });
          snackbarDispatch({
            type: SnackbarActionType.SHOW,
            payload: {
              message: 'Błąd serwera',
              type: SnackbarType.ERROR,
            },
          });
          return Promise.reject(error);
        }
        if (error.response?.status === 401) {
          userDispatch({ type: UserActionType.LOG_OUT });
          snackbarDispatch({
            type: SnackbarActionType.SHOW,
            payload: {
              message: error.response?.data?.title,
              type: SnackbarType.ERROR,
            },
          });
          return Promise.reject(error);
        }
        if (error.response?.status === 400 && error.response?.data?.errors) {
          snackbarDispatch({
            type: SnackbarActionType.SHOW,
            payload: {
              message: error.response?.data?.errors[0],
              type: SnackbarType.ERROR,
            },
          });
          return;
        }
        if (error.response?.status === 400 && error.response?.data?.title) {
          snackbarDispatch({
            type: SnackbarActionType.SHOW,
            payload: {
              message: error.response?.data?.title,
              type: SnackbarType.ERROR,
            },
          });
          return;
        }

        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarDispatch, userDispatch]);

  return <>{children}</>;
};
