import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, TextField, Pagination, useTheme, Typography, useMediaQuery } from '@mui/material';
import {
  PaginationContainer,
  PaginationContainerMobile,
  PaginationInputBox,
  PaginationItem,
} from './Pagination.styles';

const countPages = (totalSize: number, limit: number): number => {
  if (limit <= 0 || totalSize <= 0) return 0;
  if (totalSize) {
    return totalSize % limit === 0 ? Math.floor(totalSize / limit) : Math.ceil(totalSize / limit);
  }
  return 0;
};

interface CustomPaginationProps {
  totalSize: number;
  limit: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

export const CustomPagination = ({ totalSize, limit, page, setPage }: CustomPaginationProps) => {
  const [tmpPage, setTmpPage] = useState<number | undefined>(page + 1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const count = countPages(totalSize, limit);

  const handlePageInput = () => {
    if (tmpPage === undefined) {
      return;
    }
    setPage(tmpPage - 1);
  };

  const isBtnDisabled = () => {
    if (tmpPage === undefined) {
      return true;
    }
    if (tmpPage > count) {
      return true;
    }
    return tmpPage <= 0;
  };

  const onPaginationInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();

    if (event.target.value === '') {
      setTmpPage(undefined);
      return;
    }
    setTmpPage(Number(event.target.value));
  };

  const isDisabled = isBtnDisabled();

  const paginationItemsDesktop = (
    <>
      <PaginationItem>
        <PaginationInputBox>
          <TextField
            id="page"
            label="Strona"
            type="number"
            variant="outlined"
            onChange={onPaginationInputChange}
            defaultValue={tmpPage && tmpPage}
            value={tmpPage && tmpPage}
            sx={{ width: '100px' }}
          />
          <Typography variant="body1">/ {count}</Typography>
        </PaginationInputBox>
      </PaginationItem>
      <PaginationItem>
        <Button
          variant="contained"
          sx={{
            height: '40px',
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '8px',
          }}
          onClick={handlePageInput}
          disabled={isDisabled}
        >
          Wybierz stronę
        </Button>
      </PaginationItem>
      <PaginationItem>
        <Pagination
          color="primary"
          page={page + 1}
          onChange={(event, page) => {
            setTmpPage(page);
            setPage(page - 1);
          }}
          count={count}
          variant="outlined"
          shape="rounded"
        />
      </PaginationItem>
    </>
  );

  const paginationItemsMobile = (
    <>
      <PaginationItem>
        <PaginationInputBox>
          <TextField
            id="page"
            label="Strona"
            type="number"
            variant="outlined"
            onChange={onPaginationInputChange}
            defaultValue={page + 1}
            sx={{ width: '100px' }}
          />
          <Typography variant="body1">/ {count}</Typography>
          <Button
            variant="contained"
            sx={{
              height: '40px',
              backgroundColor: theme.palette.primary.dark,
              borderRadius: '8px',
            }}
            onClick={handlePageInput}
            disabled={isDisabled}
          >
            Wybierz stronę
          </Button>
        </PaginationInputBox>
      </PaginationItem>
      <PaginationItem>
        <Pagination
          color="primary"
          page={page + 1}
          onChange={(event, page) => {
            setTmpPage(page - 1);
            setPage(page - 1);
          }}
          count={count}
          variant="outlined"
          shape="rounded"
        />
      </PaginationItem>
    </>
  );

  return !isMobile ? (
    <PaginationContainer>{paginationItemsDesktop}</PaginationContainer>
  ) : (
    <PaginationContainerMobile>{paginationItemsMobile}</PaginationContainerMobile>
  );
};
