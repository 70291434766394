import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { UserProps } from '@contexts';

const UserGridColumns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'E-mail',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Imie',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
  },
  {
    field: 'surname',
    headerName: 'Nazwisko',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
  },
  {
    field: 'roles',
    headerName: 'Rola',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      if (params.row.roles[0] === 'Administrator')
        return (
          <Chip
            label="Admin"
            style={{
              backgroundColor: '#E9FCD4',
              borderColor: '#AAF27F',
              border: 'solid',
              borderWidth: '1px',
              color: '#229A16',
            }}
          />
        );
      else
        return (
          <Chip
            label="Klient"
            style={{
              backgroundColor: '#FFF7CD',
              borderColor: '#FFE16A',
              border: 'solid',
              borderWidth: '1px',
              color: '#B78103',
            }}
          />
        );
    },
    width: 150,
    sortable: false,
  },
  {
    field: 'isActive',
    headerName: 'Konto aktywne',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<UserProps>) =>
      params.row.isActive ? (
        <Chip
          label="Aktywny"
          style={{
            backgroundColor: '#E9FCD4',
            borderColor: '#AAF27F',
            border: 'solid',
            borderWidth: '1px',
            color: '#229A16',
          }}
        />
      ) : (
        <Chip
          label="Nieaktywny"
          style={{
            backgroundColor: '#FFF7CD',
            borderColor: '#FFE16A',
            border: 'solid',
            borderWidth: '1px',
            color: '#B78103',
          }}
        />
      ),
    flex: 1,
    sortable: false,
  },
];

export { UserGridColumns };
