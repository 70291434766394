import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button, Box, Typography, useTheme } from '@mui/material';
import { Input, Snackbar } from '@components';
import { SnackbarContext, UserProps } from '@contexts';
import { Dispatch, SetStateAction, useContext } from 'react';
import updateUserData from 'src/api/updateUser';
import getUser from 'src/api/getUser';

const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

interface IFormProfileInput {
  name: string;
  surname: string;
  email: string;
}

interface IFormPasswordInput {
  password: string;
}

const profileValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
});

const passwordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .matches(
      passwordRegexp,
      'Hasło musi zawierać conajmniej osiem znaków, duża i małą literę, cyfrę i znak specjalny. Znaki specjalne - @$!%*?&.'
    )
    .required('Pole obowiązkowe'),
});

interface UserProfileProps {
  user: UserProps;
  setUser: Dispatch<SetStateAction<UserProps>>;
}

export const UserProfileForm = ({ user, setUser }: UserProfileProps): JSX.Element => {
  const theme = useTheme();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormProfileInput>({
    mode: 'onBlur',
    defaultValues: {
      name: user.name || '',
      surname: user.surname || '',
      email: user.email || '',
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const {
    handleSubmit: handlePasswordSubmit,
    control: passwordControl,
    reset: resetPassword,
    formState: { errors: errorsPassword, isDirty: isPaswordDirty, isValid: isPasswordValid },
  } = useForm<IFormPasswordInput>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(passwordValidationSchema),
  });

  const onSubmit: SubmitHandler<IFormProfileInput> = async ({ name, surname, email }) => {
    if (isValid) {
      try {
        await updateUserData({
          snackbarDispatch,
          newUser: {
            id: user.id,
            roles: 'Client',
            name,
            email: email === user.email ? undefined : email,
            surname,
          },
        });
        if (user.id) {
          getUser({ setUser, userId: user.id });
        }
      } catch (e) {
        console.log(e);
      }
    }

    reset({
      name,
      surname,
      email,
    });
  };

  const onPasswordSubmit: SubmitHandler<IFormPasswordInput> = async ({ password }) => {
    if (isPasswordValid) {
      try {
        await updateUserData({
          snackbarDispatch,
          newUser: {
            id: user.id,
            name: user.name,
          },
          password,
        });
      } catch (e) {
        console.log(e);
      }
    }

    resetPassword();
  };

  return (
    <Box>
      <form key={'profile'} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            marginBottom: '20px',
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.name}
                helperText={errors.name && errors.name?.message && errors.name.message}
                label="Imię"
                autoComplete="Imię"
                ref={null}
              />
            )}
          />
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.surname}
                helperText={errors.surname && errors.surname?.message && errors.surname.message}
                label="Nazwisko"
                autoComplete="Nazwisko"
                ref={null}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.email}
                helperText={errors.email && errors.email?.message && errors.email.message}
                label="E-mail"
                autoComplete="E-mail"
                ref={null}
              />
            )}
          />
          <Input
            label="Rola"
            value={user.roles[0] === 'Client' ? 'Klient' : 'Admin'}
            ref={null}
            disabled={true}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey[800],
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: '8px',
            }}
            type="submit"
            disabled={!isDirty || !isValid}
          >
            <Typography> Aktualizuj profil </Typography>
          </Button>
        </Box>
      </form>
      {user.roles[0] === 'Client' && (
        <form key={'password'} onSubmit={handlePasswordSubmit(onPasswordSubmit)} autoComplete="false">
          <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
            <Controller
              name="password"
              control={passwordControl}
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errorsPassword.password}
                  helperText={
                    errorsPassword.password && errorsPassword.password?.message && errorsPassword.password.message
                  }
                  label="Hasło"
                  type="password"
                  ref={null}
                />
              )}
            />

            <Button
              variant="contained"
              sx={{
                borderRadius: '8px',
              }}
              type="submit"
              disabled={!isPaswordDirty}
            >
              <Typography>Zmień hasło </Typography>
            </Button>
          </Box>
        </form>
      )}
      <Snackbar />
    </Box>
  );
};
