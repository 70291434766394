import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';
import { UserProps } from 'src/contexts/UserContext';

interface UpdateUserDataProps {
  snackbarDispatch: SnackbarAction;
  newUser: Partial<UserProps>;
  password?: string;
}

const updateUserData = async ({ snackbarDispatch, newUser, password }: UpdateUserDataProps) => {
  try {
    const { status } = await httpsService.put<UserProps>(Endpoint.UPDATE_USER_DATA, {
      ...newUser,
      role: newUser.roles,
      password,
    });

    if (status === 204) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Zaktualizowano użytkownika ${newUser.name}`,
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export default updateUserData;
