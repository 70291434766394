import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';
import { NewUserProps } from './types';

interface CreateNewUserProps {
  snackbarDispatch: SnackbarAction;
  user: NewUserProps;
}

const createNewuser = async ({ snackbarDispatch, user }: CreateNewUserProps) => {
  httpsService
    .post<NewUserProps>(Endpoint.USER_DATA, user)
    .then((res) => {
      if (res.status === 201) {
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: 'Utworzono nowego użytkownika',
            type: SnackbarType.SUCCESS,
          },
        });
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export default createNewuser;
