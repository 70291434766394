import { styled } from '@mui/material';
import { Row } from '@components';

const Container = styled(Row)(({ theme }) => ({
  margin: 10,
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: 10,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    rowGap: 10,
  },
}));

export { Container };
