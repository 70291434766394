import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import { PATH } from '@router';

import { ISideNavOption } from './types';

export const optionMap = {
  overview: {
    to: `/${PATH.DASHBOARD}/${PATH.PANEL}`,
    Icon: <SearchIcon />,
    title: 'Podgląd odczytów',
  },
  locations: {
    to: `/${PATH.DASHBOARD}/${PATH.LOCATIONS_LIST}`,
    Icon: <LocationSearchingIcon />,
    title: 'Lokalizacje',
  },
  reports: {
    to: `/${PATH.DASHBOARD}/${PATH.REPORTS}`,
    Icon: <TextSnippetRoundedIcon />,
    title: 'Raporty',
    disabled: true,
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.REPORTS}/${PATH.MONTHLY_REPORT}`,
        Icon: <CalendarViewMonthIcon />,
        title: 'Miesięczny',
      },
    ],
  },
  users: {
    to: `/${PATH.DASHBOARD}/${PATH.USERS_LIST}`,
    Icon: <PeopleRoundedIcon />,
    title: 'Użytkownicy',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_USER}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
    ],
  },
  my: {
    to: `/${PATH.DASHBOARD}/${PATH.MY_PROFILE}`,
    Icon: <PermContactCalendarRoundedIcon />,
    title: 'Profil',
  },
  customers: {
    to: `/${PATH.DASHBOARD}/${PATH.CUSTOMER_LIST}`,
    Icon: <MapsHomeWorkRoundedIcon />,
    title: 'Organizacje',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_CUSTOMER}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
    ],
  },
  logout: {
    to: '/',
    Icon: <LogoutIcon />,
    title: 'Wyloguj',
  },
};

export const useSideNavOptions = () => {
  const topNavLinks: ISideNavOption[] = [
    optionMap.overview as ISideNavOption,
    optionMap.my as ISideNavOption,
    optionMap.reports as ISideNavOption,
  ];

  const adminNavLinks: ISideNavOption[] = [
    optionMap.locations as ISideNavOption,
    optionMap.users as ISideNavOption,
    optionMap.customers as ISideNavOption,
  ];

  const bottomNavLinks: ISideNavOption[] = [optionMap.logout as ISideNavOption];

  return {
    topNavLinks,
    adminNavLinks,
    bottomNavLinks,
  };
};
