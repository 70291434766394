import { IFilters } from '@api/types';

import { Container } from './filter-bar.styles';
import { Inputs } from './inputs/inputs.components';
import { Buttons } from './buttons/buttons.component';

interface IFilterBarProps {
  onChangeParams: (params: IFilters) => void;
  initialParams: IFilters;
  isReportBar: boolean;
  filters: IFilters;
}

export const FilterBar = ({ onChangeParams, initialParams, isReportBar, filters }: IFilterBarProps) => {
  return (
    <Container>
      <Inputs onChangeParams={onChangeParams} initialParams={initialParams} isReportBar={isReportBar} />
      <Buttons isReportBar={isReportBar} filters={filters} />
    </Container>
  );
};
