export enum PATH {
  LOGIN = '/',
  RESET_PASSWORD = '/odzyskaj-haslo',
  NEW_PASSWORD = '/password/reset',
  DASHBOARD = 'panel',
  NOT_FOUND = '*',
  STYLEGUIDE = 'styles',
  PANEL = 'zarzadzanie',
  DEVICE = 'zarzadzanie/:id/:type',
  DEVICE_DETAILS_OVERVIEW = `informacje-ogolne`,
  DEVICE_DETAILS_ALERTS = 'alerty',
  DEVICE_DETAILS_MEASUREMENTS = 'pomiary',
  ADD_DEVICE = 'dodaj-urzadzenie',
  USERS_LIST = 'lista-uzytkownikow',
  ADD_USER = 'dodaj-uzytkownika',
  MY_PROFILE = 'profil',
  PASSWORD = 'haslo',
  CUSTOMER_LIST = 'lista-organizacji',
  ADD_CUSTOMER = 'dodaj-organizacje',
  LOCATIONS_LIST = `lokalizacje`,
  REPORTS = 'raporty',
  MONTHLY_REPORT = 'miesieczny',
}
