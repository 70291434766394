import { Endpoint } from '@api';
import { httpsService } from '@services';
import { Dispatch, SetStateAction } from 'react';
import { UserProps } from '@contexts';

interface GetUserProps {
  userId: string;
  setUser: Dispatch<SetStateAction<UserProps>>;
}

const getUser = async ({ setUser, userId }: GetUserProps) => {
  try {
    const { data, status } = await httpsService.get<UserProps>(`${Endpoint.USER_DATA}/${userId}`);

    if (status === 200) {
      setUser(data);
    }
  } catch (err) {
    console.error(err);
  }
};

export default getUser;
