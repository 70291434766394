import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType, UserProps } from '@contexts';
import { httpsService } from '@services';
import { Dispatch, SetStateAction } from 'react';
import { ActivatingUserProps } from './types';

interface ActivateUserProps {
  snackbarDispatch: SnackbarAction;
  setUser: Dispatch<SetStateAction<UserProps>>;
  userId: string;
  active: boolean;
}

const activateUser = async ({ snackbarDispatch, setUser, userId, active }: ActivateUserProps) => {
  httpsService
    .put<ActivatingUserProps>(Endpoint.USER_ACTIVATION, {
      userId,
      isActive: active,
    })
    .then((res) => {
      if (res.status === 204) {
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: active ? 'Użytkownik jest aktywny' : 'Deaktywowano użytkownika',
            type: SnackbarType.SUCCESS,
          },
        });
        setUser((prevState) => ({
          ...prevState,
          isActive: active,
        }));
      }
    })
    .catch((e) => {
      console.log('error', e);
    });
};

export default activateUser;
