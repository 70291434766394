import { ModalActionType, ModalContext, SnackbarContext, UserProps } from '@contexts';
import { Box, Typography, useTheme, Button, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import updateUserData from 'src/api/updateUser';
import UserProfile from './UserProfile';

interface ConfirmationDialogProps {
  user: UserProps;
}

const ConfirmationDialog = ({ user }: ConfirmationDialogProps) => {
  const theme = useTheme();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { modalDispatch } = useContext(ModalContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onConfirm = async () => {
    await updateUserData({
      snackbarDispatch,
      newUser: {
        id: user.id,
        name: user.name,
        roles: 'Administrator',
      },
    });
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <UserProfile userId={user.id} />,
      },
    });
  };

  const onDecline = () => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <UserProfile userId={user.id} />,
      },
    });
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="body1" textAlign={'center'}>
        Czy chcesz nadać użytkownikowi {user.email} prawa administratora? Ta operacja jest nieodwracalna.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          columnGap: '10px',
          rowGap: '10px',
          padding: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.success.dark,
            '&:hover': {
              backgroundColor: theme.palette.success.main,
            },
          }}
          onClick={onConfirm}
        >
          <Typography variant="button" color="white">
            Potwierdzam
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.error.dark,
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          }}
          onClick={onDecline}
        >
          <Typography variant="button" color="white">
            Rezygnuje
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmationDialog;
