import { Row, SkeletonLoader } from '@components';
import { ModalActionType, ModalContext, SnackbarContext, UserProps } from '@contexts';
import { DialogContent, useTheme, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import activateUser from 'src/api/activateUser';
import getUser from 'src/api/getUser';
import ConfirmationDialog from './ConfirmationDialog';
import { UserProfileForm } from './UserProfile.form';
import { UserProfileContainer } from './UserProfile.styles';

interface UserProfileProps {
  userId?: string;
}

const UserProfile = ({ userId }: UserProfileProps) => {
  const theme = useTheme();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { modalDispatch } = useContext(ModalContext);
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (userId) {
      getUser({ setUser, userId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activation = () => {
    activateUser({
      snackbarDispatch,
      userId: user?.id || '',
      active: !user?.isActive,
      setUser,
    });
  };

  const onUserRoleChange = () => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <ConfirmationDialog user={user} />,
      },
    });
  };

  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        rowGap: '20px',
      }}
    >
      {Object.keys(user).length > 0 ? (
        <UserProfileContainer>
          <Box>
            <Typography variant="h4" textAlign="center">
              Profil użytkownika {user.name}
            </Typography>
          </Box>

          <Box sx={{ alignSelf: 'center', width: '100%' }}>
            <UserProfileForm setUser={setUser} user={user} />
          </Box>
          <Box>
            {user.roles[0] === 'Client' && (
              <Row
                sx={{
                  flexDirection: isMobile ? 'column' : 'row',
                  rowGap: '10px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: '40%',
                    height: '48px',
                    borderRadius: '8px',
                    backgroundColor: user.isActive ? theme.palette.primary.dark : theme.palette.primary.dark,
                    '&:hover': {
                      backgroundColor: user.isActive ? theme.palette.primary.main : theme.palette.primary.main,
                    },
                  }}
                  onClick={activation}
                >
                  <Typography variant="button" color="white">
                    {user.isActive ? 'Deaktywuj' : 'Aktywuj'}
                  </Typography>
                </Button>
                {user.isActive && (
                  <Button
                    variant="contained"
                    sx={{
                      width: '40%',
                      height: '48px',
                      borderRadius: '8px',
                      backgroundColor: theme.palette.primary.dark,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    onClick={onUserRoleChange}
                  >
                    <Typography variant="button" color="white">
                      Mianuj administratorem
                    </Typography>
                  </Button>
                )}
              </Row>
            )}
          </Box>
        </UserProfileContainer>
      ) : (
        <Box>
          <SkeletonLoader loadersCount={6} />
        </Box>
      )}
    </DialogContent>
  );
};

export default UserProfile;
