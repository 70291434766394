import React, { useContext } from 'react';
import * as yup from 'yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@components';
import {
  Typography,
  Box,
  useTheme,
  Button,
  MenuItem,
  FormHelperText,
  Select,
  SelectChangeEvent,
  InputLabel,
} from '@mui/material';
import { ACCOUNT_TYPES, NewUserProps } from '@api/types';
import { ModalActionType, ModalContext, SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import createNewuser from 'src/api/createNewUser';
import ConfirmationDialog from './ConfirmationDialog';

const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

const newAccountValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .matches(
      passwordRegexp,
      'Hasło musi zawierać conajmniej osiem znaków, duża i małą literę, cyfrę i znak specjalny. Znaki specjalne - @$!%*?&.'
    )
    .required('Hasło jest wymagane'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same'),
});

export const AddUserForm: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<NewUserProps>({
    resolver: yupResolver(newAccountValidationSchema),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: 'Client',
    },
  });

  const handleAccountTypeChange = (event: SelectChangeEvent<string>) => {
    setValue('role', event.target.value as ACCOUNT_TYPES);
  };

  const onSubmit: SubmitHandler<NewUserProps> = async ({ name, surname, email, password, confirmPassword, role }) => {
    createNewuser({
      snackbarDispatch,
      user: {
        name,
        surname,
        email,
        password,
        confirmPassword,
        role,
      },
    });

    reset();
  };

  return (
    <form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '30px',
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Imię"
              autoComplete="false"
              ref={null}
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.surname}
              helperText={errors.surname && errors.surname?.message && errors.surname.message}
              label="Nazwisko"
              ref={null}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.email}
              helperText={errors.email && errors.email?.message && errors.email.message}
              label="E-mail"
              autoComplete="false"
              ref={null}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.password}
              helperText={errors.password && errors.password?.message && errors.password.message}
              type="password"
              autoComplete="off"
              label="Hasło"
              ref={null}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword && errors.confirmPassword?.message && errors.confirmPassword.message}
              type="password"
              label="Potwierdź hasło"
              ref={null}
            />
          )}
        />
        <InputLabel sx={{ marginBottom: '-20px' }} htmlFor="network_type-select">
          Wybierz typ konta
        </InputLabel>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <>
              <Select {...field} error={!!errors.role} id="role-select" ref={null} onChange={handleAccountTypeChange}>
                <MenuItem key="1" value="Administrator">
                  <em>Admin</em>
                </MenuItem>
                <MenuItem key="2" value="Client">
                  <em>Klient</em>
                </MenuItem>
              </Select>
              <FormHelperText>{errors.role && errors.role?.message && errors.role.message}</FormHelperText>
            </>
          )}
        />
        <Button
          variant="contained"
          sx={{
            width: '100%',
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
          }}
          disabled={!isDirty}
          onClick={() => {
            if (isValid) {
              modalDispatch({
                type: ModalActionType.SHOW,
                payload: {
                  content: <ConfirmationDialog resetForm={reset} submitAddUserForm={handleSubmit(onSubmit)} />,
                },
              });
            } else {
              snackbarDispatch({
                type: SnackbarActionType.SHOW,
                payload: {
                  message: 'Niepoprawne dane w formularzu',
                  type: SnackbarType.ERROR,
                },
              });
              handleSubmit(onSubmit);
            }
          }}
        >
          <Typography variant="button" color="white">
            Dodaj nowe konto
          </Typography>
        </Button>
      </Box>
    </form>
  );
};
