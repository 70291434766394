import { ModalActionType, ModalContext } from '@contexts';
import { Box, Typography, useTheme, Button, useMediaQuery } from '@mui/material';
import { useContext } from 'react';

interface ConfirmationDialogProps {
  submitAddUserForm: () => void;
  resetForm: () => void;
}

const ConfirmationDialog = ({ submitAddUserForm, resetForm }: ConfirmationDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { modalDispatch } = useContext(ModalContext);

  const onConfirm = () => {
    submitAddUserForm();
    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  const onDecline = () => {
    resetForm();
    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="body1" textAlign={'center'}>
        Przekaż użytkownikowi adres e-mail, oraz hasło. Użytkownik powinien zmienić hasło przy okazji pierwszego
        logowania do systemu.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          columnGap: '10px',
          rowGap: '20px',
          padding: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.success.dark,
            '&:hover': {
              backgroundColor: theme.palette.success.main,
            },
          }}
          onClick={onConfirm}
        >
          <Typography variant="button" color="white">
            Utwórz użytkownika
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.error.dark,
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          }}
          onClick={onDecline}
        >
          <Typography variant="button" color="white">
            Rezygnuje
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmationDialog;
