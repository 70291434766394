import { IFilters, MeasurementType } from '@api/types';
import { useCallback, useEffect, useState } from 'react';

interface IFilterParamsProps {
  initialParams: IFilters;
  onChangeParams: (params: IFilters) => void;
}

export const useFilter = ({ initialParams, onChangeParams }: IFilterParamsProps) => {
  const [params, setParams] = useState<IFilters>(initialParams);

  useEffect(() => {
    if (Object.values(initialParams).every((value) => value === '')) {
      setParams(initialParams);
    }
  }, [initialParams]);

  const handleChangeParams = useCallback(
    (name: keyof IFilters, value: string | number | MeasurementType[]) => {
      if (name === 'cityId' && typeof value === 'string') {
        onChangeParams({ ...params, cityId: value, streetId: '', buildingId: '' });
        setParams({ ...params, cityId: value, streetId: '', buildingId: '' });
      }
      if (name === 'streetId' && typeof value === 'string') {
        onChangeParams({ ...params, streetId: value, buildingId: '' });
        setParams({ ...params, streetId: value, buildingId: '' });
      }
      if (name === 'buildingId' && typeof value === 'string') {
        onChangeParams({ ...params, buildingId: value });
        setParams({ ...params, buildingId: value });
      }
      if (name === 'onlyAlerts' && typeof value === 'string') {
        onChangeParams({ ...params, onlyAlerts: value });
        setParams({ ...params, onlyAlerts: value });
      }
      if (name === 'forDay' && typeof value === 'number') {
        onChangeParams({ ...params, forDay: value });
        setParams({ ...params, forDay: value });
      }
      if (name === 'measurementTypes' && Array.isArray(value)) {
        onChangeParams({ ...params, measurementTypes: value });
        setParams({ ...params, measurementTypes: value });
      }
    },
    [onChangeParams, params, setParams]
  );

  const filtersChange = (name: keyof IFilters, value: string | number | MeasurementType[]) => {
    handleChangeParams(name, value);
  };

  return {
    filtersChange,
    params,
  };
};
