enum Endpoint {
  LOGIN = 'api/account/login',
  USER_DATA = 'api/account',
  MY_USER_DATA = 'api/account/my',
  MY_USER_PASSWORD = 'api/account/my/password',
  RESET_PASSWORD_INIT = 'api/account/password/reset/init',
  RESET_PASSWORD = 'api/account/password/reset',
  UPDATE_MY_USER_DATA = 'api/account/my/data',
  UPDATE_USER_DATA = 'api/account/data',
  USER_ACTIVATION = 'api/account/user/activation',
  DEVICE_LIST = 'api/devices',
  DEVICE_WATER_DETAILS = 'api/waterMeters/:id',
  DEVICE_HEATER_DETAILS = 'api/heatMeters/:id',
  DEVICE_WATER_MESUREMENTS = 'api/waterMeters/:id/measurements',
  DEVICE_HEATER_MEASUREMENTS = 'api/heatMeters/:id/measurements',
  DEVICE_WATER_ALERTS = 'api/waterMeters/:id/alerts',
  DEVICE_HEATER_ALERTS = 'api/heatMeters/:id/alerts',
  HEAT_METER_EXPIRATION_DATE = 'api/heatMeters/:meterId/expirationDate',
  HEAT_METER_LEGALIZATION_DATE = 'api/heatMeters/:meterId/legalizationEndDate',
  WATER_METER_EXPIRATION_DATE = 'api/waterMeters/:meterId/expirationDate',
  WATER_METER_LEGALIZATION_DATE = 'api/waterMeters/:meterId/legalizationEndDate',
  WATER_METER_NETWORK = 'api/waterMeters/:meterId/network',
  HEAT_METER_NETWORK = 'api/heatMeters/:meterId/network',
  DEVICE_LIST_ALERTS = 'api/devices/alerts',
  GENERAL_REPORT = 'api/reports',
  WATER_METER_REPORT_COLD = 'api/locations/reports/waterMeters/:meterId/cold',
  WATER_METER_REPORT_HOT = 'api/locations/reports/waterMeters/:meterId/hot',
  HEAT_METER_REPORT = 'api/locations/reports/heatMeters/:meterId',
  CITIES = 'api/address/cities',
  STREETS_COMMON = 'api/address/streets',
  STREETS = 'api/address/cities/:cityId/streets',
  BUILDINGS_COMMON = 'api/address/buildings',
  BUILDINGS = 'api/address/cities/streets/:streetId/buildings',
  FLATS_COMMON = 'api/address/flats',
  FLATS = 'api/address/cities/streets/buildings/:buildingId/flats',
  USER_DEVICE = 'api/UserDevice',
  USER_DEVICE_USERS = 'api/UserDevice/users',
  CUSTOMERS_LIST = 'api/organizations',
  LOCATION_DASHBOARD = 'api/locations/dashboard/?limit=:limit&offset=:offset&cityId=:cityId&streetId=:streetId&buildingId=:buildingId&flatId=:flatId&onlyAlerts=:onlyAlerts',
  LOCATION_METERS = 'api/locations/dashboard/:locationId/meters',
}

export default Endpoint;
