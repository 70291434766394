import React, { useContext } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { Input } from '@components';
import { SnackbarContext } from '@contexts';
import { updateMyUserPassword } from '@api';
import { PATH } from '@router';
import { useNavigate } from 'react-router-dom';

const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

interface IFormInput {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .required('Hasło jest wymagane'),
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .matches(
      passwordRegexp,
      'Hasło musi zawierać conajmniej osiem znaków, duża i małą literę, cyfrę i znak specjalny. Znaki specjalne - @$!%*?&.'
    )
    .required('Hasło jest wymagane'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same'),
});

export const ChangePassword: React.FC = () => {
  const { snackbarDispatch } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ oldPassword, password, confirmPassword }) => {
    if (isValid) {
      updateMyUserPassword({
        snackbarDispatch,
        oldPassword,
        newPassword: confirmPassword,
      });
    }

    reset();
  };

  const navigateToProfile = () => {
    navigate(`/${PATH.DASHBOARD}/${PATH.MY_PROFILE}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.oldPassword}
              type="password"
              helperText={errors.oldPassword && errors.oldPassword?.message && errors.oldPassword.message}
              label="Stare hasło"
              autoComplete={'off'}
              ref={null}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.password}
              helperText={errors.password && errors.password?.message && errors.password.message}
              type="password"
              label="Nowe Hasło"
              autoComplete={'off'}
              ref={null}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.confirmPassword}
              type="password"
              helperText={errors.confirmPassword && errors.confirmPassword?.message && errors.confirmPassword.message}
              label="Potwierdź nowe hasło"
              autoComplete={'off'}
              ref={null}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{
            borderRadius: '8px',
          }}
          disabled={!isDirty}
          type="submit"
        >
          <Typography variant="body1">Ustaw nowe hasło</Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: '8px',
          }}
          onClick={navigateToProfile}
        >
          <Typography variant="body1">Zarządzaj profilem</Typography>
        </Button>
      </Box>
    </form>
  );
};
