import { ICustomer, LocalStorageKey } from '@api/types';
import { setItem } from '@services';
import { createContext, Dispatch, Reducer } from 'react';
import { UserProps } from './types';

export enum UserActionType {
  LOG_IN = 'logIn',
  LOG_OUT = 'logOut',
  GET_USER_DATA = 'getUserData',
  SET_CUSTOMER = 'setCustomer',
}

type Action =
  | {
      type: UserActionType.LOG_IN;
      payload: { accessToken: string; expires: string; id: string };
    }
  | {
      type: UserActionType.LOG_OUT;
    }
  | {
      type: UserActionType.GET_USER_DATA;
      payload: UserProps;
    }
  | {
      type: UserActionType.SET_CUSTOMER;
      payload: {
        currentCustomer: ICustomer | undefined;
      };
    };

type UserState = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
  isAutheticated: boolean;
  accessToken: string;
  expires: string;
  sessionExpired: boolean;
  currentCustomer: ICustomer | undefined;
};

type UserAction = Dispatch<Action>;

interface UserContextContent {
  userState: UserState;
  userDispatch: UserAction;
}

const initialState: UserState = {
  isAutheticated: false,
  name: '',
  surname: '',
  email: '',
  roles: '',
  accessToken: '',
  expires: '',
  id: '',
  sessionExpired: false,
  currentCustomer: undefined,
};

const UserContext = createContext<UserContextContent>({
  userState: initialState,
  userDispatch: () => initialState,
});

const userReducer: Reducer<UserState, Action> = (state, action) => {
  console.log('Action', action.type);

  switch (action.type) {
    case UserActionType.LOG_IN: {
      setItem(LocalStorageKey.LOGIN_RESPONSE_BODY, action.payload);
      const { accessToken, expires, id } = action.payload;
      return {
        ...state,
        accessToken,
        expires,
        id,
        isAutheticated: true,
        sessionExpired: false,
      };
    }
    case UserActionType.LOG_OUT: {
      window.localStorage.removeItem(LocalStorageKey.LOGIN_RESPONSE_BODY);
      window.localStorage.removeItem(LocalStorageKey.MY_PROFILE_DATA);
      window.localStorage.removeItem(LocalStorageKey.FILTERS);
      if (state.roles !== 'Administrator') {
        window.localStorage.removeItem(LocalStorageKey.CURRENT_CUSTOMER);
      }
      return {
        ...state,
        accessToken: '',
        expires: '',
        id: '',
        isAutheticated: false,
        sessionExpired: true,
        name: '',
        surname: '',
        email: '',
        roles: 'undefined',
      };
    }
    case UserActionType.GET_USER_DATA: {
      const { name, surname, email, roles, currentCustomer } = action.payload;
      setItem(LocalStorageKey.MY_PROFILE_DATA, action.payload);
      if (roles === 'Client') {
        setItem(LocalStorageKey.CURRENT_CUSTOMER, currentCustomer);
      }
      return {
        ...state,
        name,
        surname,
        email,
        roles,
        currentCustomer,
      };
    }
    case UserActionType.SET_CUSTOMER: {
      const { currentCustomer } = action.payload;
      if (currentCustomer?.id) {
        setItem(LocalStorageKey.CURRENT_CUSTOMER, currentCustomer);
      } else {
        window.localStorage.removeItem(LocalStorageKey.CURRENT_CUSTOMER);
      }
      return {
        ...state,
        currentCustomer,
      };
    }
  }
};

export { userReducer, UserContext, initialState };
export { UserAction, UserState };
