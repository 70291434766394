import React, { useContext } from 'react';
import { Grid, MenuList, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@contexts';

import { Logo } from './components/logo';
import { ShadowSeparator } from './components/shadowSeparator';
import { useSideNavOptions } from '../menu-options';
import { MenuItem } from '../menu-item';
import { useStyles } from './MenuOptions.styles';

interface MenuOptionsProps {
  logoutUser: () => void;
}

export const MenuOptions: React.FC<MenuOptionsProps> = ({ logoutUser }) => {
  const { classes } = useStyles();
  const { topNavLinks, adminNavLinks, bottomNavLinks } = useSideNavOptions();
  const navigate = useNavigate();
  const { userState } = useContext(UserContext);
  const handleMenuItemClick = (to: string) => {
    navigate(to);
  };
  return (
    <Grid item xs={false} sx={{ overflowX: 'auto' }}>
      <MenuList className={classes.container}>
        <div>
          <Logo />
          {process.env.REACT_APP_RUNNING_ENV === 'development' && (
            <Typography variant="subtitle2" textAlign="center">
              Środowisko testowe v.{process.env.REACT_APP_VERSION}
            </Typography>
          )}
          <div className={classes.orgNameContainer}>
            <Typography className={classes.orgNameText} variant="subtitle2" textAlign="center">
              {userState.currentCustomer?.name}
            </Typography>
          </div>
          <Divider sx={{ marginBottom: 2 }} />
          {topNavLinks.map((link) => (
            <MenuItem
              key={link.to}
              label={link.title}
              icon={link.Icon}
              handleClick={() => {
                handleMenuItemClick(link.to);
              }}
              disabled={link.disabled}
              to={link.to}
              nestedOptions={link.nestedOptions}
            />
          ))}
          {userState.roles === 'Administrator' && (
            <>
              <Divider sx={{ marginBottom: 2 }} />
              {adminNavLinks.map((link) => (
                <MenuItem
                  key={link.to}
                  label={link.title}
                  icon={link.Icon}
                  disabled={link.disabled}
                  handleClick={() => {
                    handleMenuItemClick(link.to);
                  }}
                  to={link.to}
                  nestedOptions={link.nestedOptions}
                />
              ))}
              <Divider sx={{ marginTop: 2 }} />
            </>
          )}
        </div>
        <div>
          <Divider sx={{ marginBottom: 2 }} />
          {bottomNavLinks.map((link) => (
            <MenuItem
              key={link.to}
              label={link.title}
              icon={link.Icon}
              disabled={link.disabled}
              handleClick={logoutUser}
              to={link.to}
              nestedOptions={link.nestedOptions}
            />
          ))}
        </div>
      </MenuList>
      <ShadowSeparator />
    </Grid>
  );
};
