import { Paper, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { AddUserForm } from './AddUser.form';
import { AddUserContainer } from './AddUser.styles';

const AddUser = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AddUserContainer>
      <Paper
        elevation={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          padding: '70px 40px',
        }}
      >
        <Typography variant="h4" textAlign="center">
          Dodaj użytkownika
        </Typography>
        <Box sx={{ width: isMobile ? '220px' : '500px' }}>
          <AddUserForm />
        </Box>
      </Paper>
    </AddUserContainer>
  );
};

export { AddUser };
